const valueTypes = {
   unknown: 0,
   string: 1,
   number: 2,
   boolean: 3,
   bigint: 4
};

const optionIfTypes = {};
optionIfTypes.ANSWER = { id: 1, name: 'Answer' };

const optionThenTypes = {};
optionThenTypes.FLAG = { id: 1, name: 'Flag' };
optionThenTypes.FAIL_OP = { id: 2, name: 'Fail Op' };
optionThenTypes.SCORE = { id: 3, name: 'Score' };
//optionThenTypes.ISSUE = { id: 4, name: 'Raise Issue' };

const operatorTypes = {};
operatorTypes.EQUALS = { id: 1, name: 'Equals' };

const channelTypes = {
   SUBMISSION: { id: 1 }
};

const reportTypes = {
   TASK: { id: 1 },
   ISSUE: { id: 2 }
};

const issueStates = {
   NEW: 0,
   CLOSED: 1 << 0,
   ASSIGNED: 1 << 1,
   ACTIONED: 1 << 2,
   RESOLVED: 1 << 3
};

const mergeTypes = {
   PROCESS: { id: 1 },
   MERGE: { id: 2 }
};

const commitStates = {
   STAGED: { id: 0 },
   COMMITTED: { id: 1 },
   CONFLICTED: { id: 2 },
   RESOLVED: { id: 3 },
   DISCARDED: { id: 4 },
   DELETED: { id: 5 }
};

const taskViewStates = {
   PRIOR: 1 << 0,
   UPCOMING: 1 << 1
};

const taskFilterTypes = {
   STATE: { id: 1 },
   TIMING: { id: 2 },
   WITH_TAGS: { id: 3 },
   WITHOUT_TAGS: { id: 4 },
   TYPE: { id: 5 },
   LOCATION: { id: 6 },
   TEAM: { id: 7 },
   POSITION: { id: 8 },
   STAFF: { id: 9 },
   FLAGGED: { id: 10 },
   SEARCH_TEXT: { id: 11 },
   OPERATION: { id: 12 },
   DATE_RANGE: { id: 13 }
};

const taskTimingFilterTypes = {
   NOT_DUE: { id: 0, name: 'Not Due', value: 1 << 0 },
   DUE: { id: 1, name: 'Due', value: 1 << 1 },
   OVERDUE: { id: 2, name: 'Overdue', value: 1 << 2 },
   MISSED: { id: 3, name: 'Missed', value: 1 << 3 },
   COMPLETE: { id: 4, name: 'Complete', value: 1 << 4 }
};

const taskTimingStates = {
   NOT_DUE: 1 << 0, // 1
   DUE: 1 << 1, // 2
   OVERDUE: 1 << 2, // 4
   MISSED: 1 << 3, // 8
   COMPLETE: 1 << 4, // 16
   COMPLETED_EARLY: 1 << 5, // 32
   COMPLETED_ONTIME: 1 << 6, // 64
   COMPLETED_LATE: 1 << 7 // 128
};

const taskStatesFilterTypes = {
   NOT_STARTED: { id: 0, name: 'Not Started', value: 1 << 0 },
   BUSY: { id: 1, name: 'Busy', value: 1 << 1 },
   SUBMITTED: { id: 2, name: 'Submitted', value: 1 << 2 },
   PENDING_SIGNOFF: { id: 3, name: 'Pending Signoff', value: 1 << 3 },
   REJECTED: { id: 4, name: 'Rejected', value: 1 << 4 },
   SIGNED_OFF: { id: 5, name: 'Signed off', value: 1 << 5 }
};

const taskMetricsAggregationTypes = {
   Daily: { id: 1, name: 'Daily', period: 'daily' },
   Weekly: { id: 2, name: 'Weekly', period: 'weekly' },
   Monthly: { id: 3, name: 'Monthly', period: 'monthly' }
};

const routineStates = {
   DISABLED: 1 << 0,
   SETTING_UP: 1 << 1
};

const reportStates = {
   DISABLED: 1 << 0, // 1
   SYSTEM: 1 << 1, // 2
   DEFAULT: 1 << 2 // 4
};

const taskStates = {
   NOT_STARTED: 1 << 0,
   BUSY: 1 << 1,
   SUBMITTED: 1 << 2,
   PENDING_SIGNOFF: 1 << 3,
   REJECTED: 1 << 4,
   SIGNED_OFF: 1 << 5,
   COMPLETE: 1 << 6
};

const messageTypes = {
   TEXT: 1 << 0,
   IMAGE: 1 << 1,
   VIDEO: 1 << 2,
   AUDIO: 1 << 3,
   DOCUMENT: 1 << 4
};

const staffStates = {
   DISABLED: 1 << 0,
   INVITED: 1 << 1,
   JOINED: 1 << 2,
   SETUP: 1 << 3,
   SECURED: 1 << 4
};

const userStates = {
   REVOKED: 1 << 0,
   PWD_RESET_REQUIRED: 1 << 1,
   NO_LOGIN: 1 << 2
};

const emailLinkTypes = { FORGOT_PASSWORD: { id: 0 } };

const formViewTypes = { ALL_FORMS: { id: 0, name: 'all forms' }, WITHOUT_TAGS: { id: 1, name: 'forms without any tags' } };

const dataSourceViewTypes = { ALL_DATASOURCES: { id: 0, name: 'all datasources' } };

const resourceViewTypes = { ALL_RESOURCES: { id: 0, name: 'all resources' } };

const taskViewTypes = {};
taskViewTypes.ALL_TASKS = { id: 0, name: 'all tasks' };
taskViewTypes.WITHOUT_TAGS = { id: 1, name: 'tasks without any tags' };

const routineViewTypes = {};
routineViewTypes.ALL_ROUTINES = { id: 0, name: 'all operations' };
routineViewTypes.WITHOUT_TAGS = { id: 1, name: 'operations without any tags' };

const staffViewTypes = {};

staffViewTypes.ALL_STAFF = { id: 0, name: 'all staff' };
staffViewTypes.STAFF_ASSIGNED_POSITION = { id: 1, name: 'staff assigned the position' };
staffViewTypes.STAFF_WHO_ARE_MEMBERSOF = { id: 2, name: 'staff who are members' };
staffViewTypes.STAFF_WHOCAN_WORK_AT_SPECIFIC_LOCATION = { id: 3, name: 'staff who can work specifically at' };

const accessLevels = {};
accessLevels.ACCOUNT_OWNER = { name: 'ao', description: 'Account Owner' };
accessLevels.ADMIN = { name: 'admin', description: 'Administrator' };
accessLevels.STAFF = { name: 'staff', description: 'Staff' };
//accessLevels.LOCATION_ADMIN = { name: 'locationadmin', description: 'Location Admin' };

const noAccountUserTypes = {};
noAccountUserTypes.STAFF = { id: 1, name: 'staff member' };

const userCreationTypes = {};
userCreationTypes.SIGNED_UP = { id: 1 };
userCreationTypes.INVITED = { id: 2 };
userCreationTypes.ADDED = { id: 3 };

const systemmSequenceTypes = {};
systemmSequenceTypes.ACCOUNTS = { id: 1, name: 'accounts', type: 'int' };
systemmSequenceTypes.USERS = { id: 2, name: 'users', type: 'int' };
systemmSequenceTypes.REFRESHTOKENS = { id: 3, name: 'refreshtokens', type: 'long' };
systemmSequenceTypes.DEVICES = { id: 4, name: 'devices', type: 'long' };

const sequenceTypes = {};
sequenceTypes.TAG = { id: 1, name: 'tags', type: 'int' };
sequenceTypes.LOCATION = { id: 2, name: 'locations', type: 'int' };
sequenceTypes.TEAM = { id: 3, name: 'teams', type: 'int' };
sequenceTypes.POSITION = { id: 4, name: 'positions', type: 'int' };
sequenceTypes.ROUTINE = { id: 5, name: 'routines', type: 'int' };
sequenceTypes.FORM = { id: 6, name: 'forms', type: 'int' };
sequenceTypes.DATASOURCE = { id: 7, name: 'datasources', type: 'int' };
sequenceTypes.ACCOUNTUSER = { id: 8, name: 'accountusers', type: 'int' };
sequenceTypes.REPORT = { id: 9, name: 'reports', type: 'int' };
sequenceTypes.CHANNELS = { id: 10, name: 'channels', type: 'int' };
sequenceTypes.FRAMES = { id: 11, name: 'frames', type: 'long' };
sequenceTypes.TASKS = { id: 12, name: 'tasks', type: 'long' };
sequenceTypes.ISSUES = { id: 13, name: 'issues', type: 'long' };
sequenceTypes.MEDIAASSETS = { id: 14, name: 'mediaassets', type: 'int' };
sequenceTypes.RESOURCES = { id: 15, name: 'resources', type: 'int' };
sequenceTypes.SUBMISSIONS = { id: 16, name: 'submissions', type: 'long' };

const resourceTypes = {};
resourceTypes.FILE = { id: 1, name: 'uploaded file' };

const qrCodeTypes = {};
qrCodeTypes.LOCATION = { id: 1, name: 'locations' };

const tagTypes = {};
tagTypes.LOCATION = { id: 1, name: 'location' };
tagTypes.ISSUE = { id: 2, name: 'issue' };
tagTypes.ROUTINE = { id: 3, name: 'routine' };
tagTypes.FORM = { id: 4, name: 'form' };
tagTypes.REPORT = { id: 5, name: 'report' };

const locationAssignmentTypes = {};
locationAssignmentTypes.UNASSIGNED = { id: 0, name: 'unassigned' };
locationAssignmentTypes.ALL_LOCATIONS = { id: 1, name: 'all locations' };
locationAssignmentTypes.SPECIFIC_LOCATION = { id: 2, name: 'specific location' };
locationAssignmentTypes.WITHIN = { id: 3, name: 'within' };
locationAssignmentTypes.AT_TAGGED_WITH = { id: 4, name: 'tagged with' };
locationAssignmentTypes.AT_LOCATION = { id: 5, name: 'at location' };

const whoAssignmentTypes = {};
whoAssignmentTypes.UNASSIGNED = { id: 0, name: 'unassigned' };
whoAssignmentTypes.ALL_TEAMS = { id: 1, name: 'all teams' };
whoAssignmentTypes.ALL_POSITIONS = { id: 2, name: 'all positions' };
whoAssignmentTypes.ALL_STAFF = { id: 4, name: 'all staff' };
whoAssignmentTypes.SPECIFIC_TEAMS = { id: 8, name: 'specific teams' };
whoAssignmentTypes.SPECIFIC_POSITIONS = { id: 16, name: 'specific positions' };
whoAssignmentTypes.SPECIFIC_STAFF = { id: 32, name: 'specific staff members' };

const whoUnionType = {};
whoUnionType.NONE = { id: 0, name: 'none' };
whoUnionType.ANY = { id: 1, name: 'any member of' };
whoUnionType.ALL = { id: 2, name: 'all members of' };
whoUnionType.EXCEPT = { id: 3, name: 'except members of' };

const positionViewTypes = {};
positionViewTypes.POSITIONS_FOR = { id: 1, name: 'positions for' };

const teamViewTypes = {};
teamViewTypes.ALL_TEAMS = { id: 1, name: 'all teams' };
teamViewTypes.ASSIGNED_TEAMS = { id: 2, name: 'assigned teams' };
teamViewTypes.UNASSIGNED_TEAMS = { id: 3, name: 'teams not currently assigned' };

const ownerTypes = {};

ownerTypes.LOCATION = { id: 0, name: 'location' };
ownerTypes.ISSUE = { id: 1, name: 'issue' };
ownerTypes.USER = { id: 2, name: 'user' };

const userViewTypes = {};

userViewTypes.ALL_USERS = { id: 0, name: 'all users' };
userViewTypes.USERS_WITHOUT_GROUPS = { id: 1, name: 'users without groups' };

const issueViewTypes = {};

issueViewTypes.ALL = { id: 0, name: 'all issues' };

// Not yet in any state (so need to be dealt with)
// order by priority then by due date ascending
issueViewTypes.TRIAGE = { id: 1, name: 'issues requiring triage' };

// Those issues which are not closed and we have missed the deadline
// order by priority then by due date ascending
issueViewTypes.OVERDUE = { id: 2, name: 'overdue issues' };

// Not closed and due date not passed
// order by priority then by due date ascending
issueViewTypes.UPCOMING_ISSUES = { id: 3, name: 'upcoming issues' };

// Open Issues assigned but not yet actioned
// order by highest priority then by due date ascending
issueViewTypes.UNACTIONED_ISSUES = { id: 4, name: 'issues not actioned' };

// Issues which have been closed
// Order by most recently closed then by priority
issueViewTypes.CLOSED_ISSUES = { id: 5, name: 'closed issues' };

// Issues assigned by me
// order by most recently assigned then priority
issueViewTypes.ASSIGNED = { id: 6, name: 'issues assigned by me' };

// Issues issues raised by me
// order by most recently raised then priority
issueViewTypes.RAISED = { id: 7, name: 'issues raised by me' };

// without tags order by priority then by due date ascending
issueViewTypes.WITHOUT_TAGS = { id: 8, name: 'issues without tags' };

// open issues without a deadline
// order by most recently raised then priority
issueViewTypes.WITHOUT_DEADLINE = { id: 9, name: 'open issues without deadline' };

// open issues without an assignment
issueViewTypes.UNASSIGNED = { id: 10, name: 'issues not assigned to anyone' };

const locationViewTypes = {};

locationViewTypes.ALL_LOCATIONS = { id: 0, name: 'all locations' };
locationViewTypes.CURRENTLY_TRADING = { id: 1, name: 'currently operating locations' };
locationViewTypes.PENDING_TRADING = { id: 2, name: 'locations which will start operating' };
locationViewTypes.DEACTIVATED_LOCATIONS = { id: 3, name: 'locations which have or will stop operating.' };
locationViewTypes.WITHOUT_TAGS = { id: 4, name: 'locations without any tags' };

const locationStatuses = {};

locationStatuses.Inactive = { id: 0 };
locationStatuses.Active = { id: 1 };
locationStatuses.Demo = { id: 2 };

const taskDayTypes = {};

taskDayTypes.ANY_DAY = { id: 0, name: 'any day' };
taskDayTypes.MONDAY = { id: 1, name: 'a monday' };
taskDayTypes.TUESDAY = { id: 2, name: 'a tuesday' };
taskDayTypes.WEDNESDAY = { id: 3, name: 'a wednesday' };
taskDayTypes.THURSDAY = { id: 4, name: 'a thursday' };
taskDayTypes.FRIDAY = { id: 5, name: 'a friday' };
taskDayTypes.SATURDAY = { id: 6, name: 'a saturday' };
taskDayTypes.SUNDAY = { id: 7, name: 'a sunday' };
taskDayTypes.ANY_WEEKDAY = { id: 8, name: 'any week day' };
taskDayTypes.EVERY_WEEKDAY = { id: 9, name: 'every week day' };

const taskClasses = {};
taskClasses.OPERATION = { id: 1, name: 'Operation' };
taskClasses.WORKORDER = { id: 2, name: 'WorkOrder' };
taskClasses.TODO = { id: 3, name: 'ToDo' };

const taskAssignmentTypeFilters = {};

taskAssignmentTypeFilters.COMPLETABLE_BY = { id: 0, name: 'completable by' };
taskAssignmentTypeFilters.ASSIGNED_TO = { id: 1, name: 'assigned to' };

const sections = {};
sections.ACT = { id: 0, name: 'act' };
sections.ORGANISE = { id: 1, name: 'organise' };
sections.OBSERVE = { id: 2, name: 'observe' };

const phoneTypes = ['Work', 'Mobile', 'Fax', 'Home', 'Pager', 'Skype', 'Other'];
const emailTypes = ['Work', 'Home', 'Other'];
const websiteTypes = ['Work', 'Personal', 'Other'];

const cadenceTypes = {};
//cadenceTypes.HOURLY = { id: 1, dual: 'Hour(s)', name: 'Hour', plural: 'Hours', cadenceName: 'Hourly' };
cadenceTypes.DAILY = { id: 2, dual: 'Day(s)', name: 'Day', plural: 'Days', cadenceName: 'Daily' };
cadenceTypes.WEEKLY = { id: 3, dual: 'Week(s)', name: 'Week', plural: 'Weeks', cadenceName: 'Weekly' };
cadenceTypes.MONTHLY = { id: 4, dual: 'Month(s)', name: 'Month', plural: 'Months', cadenceName: 'Monthly' };
cadenceTypes.ANNUALLY = { id: 5, dual: 'Year(s)', name: 'Year', plural: 'Years', cadenceName: 'Annually' };

const occurrences = {};
occurrences.AS_NEEDED = { id: 1, name: 'As Needed' };
occurrences.ONCE = { id: 2, name: 'Once' };
occurrences.DAILY = { id: 3, name: 'Daily' };
occurrences.WEEKLY = { id: 4, name: 'Weekly' };
occurrences.MONTHLY = { id: 5, name: 'Monthly' };
occurrences.ANNUALLY = { id: 6, name: 'Annually' };
occurrences.REGULARLY = { id: 7, name: 'Regularly' };
//occurrences.AFTER_COMPLETION = { id: 7, name: 'Again after completion' };

const daysOfWeek = {};
daysOfWeek.MON = { id: 1, name: 'Monday', key: 'mon', value: 1 << 0, plural: 'Mondays' };
daysOfWeek.TUE = { id: 2, name: 'Tuesday', key: 'tue', value: 1 << 1, plural: 'Tuesdays' };
daysOfWeek.WED = { id: 3, name: 'Wednesday', key: 'wed', value: 1 << 2, plural: 'Wednesdays' };
daysOfWeek.THU = { id: 4, name: 'Thursday', key: 'thu', value: 1 << 3, plural: 'Thursdays' };
daysOfWeek.FRI = { id: 5, name: 'Friday', key: 'fri', value: 1 << 4, plural: 'Fridays' };
daysOfWeek.SAT = { id: 6, name: 'Saturday', key: 'sat', value: 1 << 5, plural: 'Saturdays' };
daysOfWeek.SUN = { id: 7, name: 'Sunday', key: 'sun', value: 1 << 6, plural: 'Sundays' };

const cadences = {};
cadences.FIRST = { id: 'first', name: '1st', value: 1 << 1 };
cadences.SECOND = { id: 'second', name: '2nd', value: 1 << 2 };
cadences.THIRD = { id: 'third', name: '3rd', value: 1 << 3 };
cadences.FOURTH = { id: 'fourth', name: '4th', value: 1 << 4 };
cadences.LAST = { id: 'last', name: 'Last', value: 1 << 0 };

const formItemTypes = {};
formItemTypes.TODO = { id: 1, name: 'To do', componentType: { default: 'todo' } };
formItemTypes.OPTIONS = { id: 2, name: 'Options', componentType: { default: 'dropdown' } };
formItemTypes.NUMBER = { id: 3, name: 'Number', componentType: { default: 'number' } };
formItemTypes.TEXT = { id: 4, name: 'Free Text', componentType: { default: 'text' } };
formItemTypes.DATETIME = { id: 6, name: 'Date & Time', componentType: { default: 'datetime' } };
formItemTypes.PHOTO = { id: 11, name: 'Photo', componentType: { default: 'photo' } };
formItemTypes.SECTION = { id: 14, name: 'Section', componentType: { default: 'section' } };
formItemTypes.REPEATER = { id: 15, name: 'Repeater', componentType: { default: 'repeater' } };
formItemTypes.REPEATER_GROUP = { id: 16, name: 'Repeater Group', componentType: { default: 'repeatergroup' } };

const datetimeFormItemTypes = {};
datetimeFormItemTypes.DATE = { id: 1, name: 'Date' };
datetimeFormItemTypes.TIME = { id: 2, name: 'Time' };
datetimeFormItemTypes.DATETIME = { id: 3, name: 'Date Time' };

const repeaterFormItemTypes = {};
repeaterFormItemTypes.LIST = { id: 1, name: 'List' };
repeaterFormItemTypes.FREE_FLOW = { id: 2, name: 'Free flow' };

const repeaterCadenceTypes = {};
repeaterCadenceTypes.FOREACH = { id: 1, name: 'entries for each item' };
repeaterCadenceTypes.EXACTLY = { id: 2, name: 'exactly' };
repeaterCadenceTypes.AT_LEAST = { id: 3, name: 'at least' };
repeaterCadenceTypes.AT_MOST = { id: 4, name: 'at most' };
repeaterCadenceTypes.ANY_NUMBER_OF = { id: 5, name: 'any number of' };

const optionsFormItemDisplayTypes = {};
optionsFormItemDisplayTypes.DROPDOWN = { id: 1, name: 'Dropdown' };
optionsFormItemDisplayTypes.BUTTONS = { id: 2, name: 'Buttons' };

const formTypes = {};
formTypes.ROUTINE = { id: 1, name: 'Routine' };
formTypes.PREVIEW = { id: 2, name: 'Preview' };

const answerStates = {
   ANSWERED: 1 << 0,
   SKIPPED: 1 << 1,
   LOCKED: 1 << 2,
   INVALID: 1 << 3
};

const fieldRequiredTypes = {};
fieldRequiredTypes.SKIPABLE = { id: 0, name: 'may be skipped' };
fieldRequiredTypes.ALWAYS = { id: 1, name: 'requries an answer' };

const formComponentNames = {
   TODOLIST: 'checklist',
   TODO: 'todo',
   TEXT: 'text',
   NUMBER: 'number',
   OPTIONS: 'options',
   PHOTO: 'photo',
   SECTION: 'section',
   REPEATER: 'repeater',
   DATETIME: 'datetime',
   REPEATER_GROUP: 'repeatergroup'
};

const actionFlags = {
   NEEDS_ACTION: 1,
   ACTIONING: 2,
   ACTIONED: 4
};

const AppConstants = {
   dataSourceViewTypes,
   userCreationTypes,
   userViewTypes,
   locationViewTypes,
   locationStatuses,
   taskDayTypes,
   phoneTypes,
   emailTypes,
   websiteTypes,
   occurrences,
   cadenceTypes,
   sections,
   resourceViewTypes,
   taskAssignmentTypeFilters,
   issueStates,
   issueViewTypes,
   tagTypes,
   ownerTypes,
   messageTypes,
   sequenceTypes,
   systemmSequenceTypes,
   locationAssignmentTypes,
   teamViewTypes,
   positionViewTypes,
   noAccountUserTypes,
   accessLevels,
   staffViewTypes,
   staffStates,
   userStates,
   routineViewTypes,
   daysOfWeek,
   cadences,
   whoAssignmentTypes,
   whoUnionType,
   emailLinkTypes,
   formItemTypes,
   formTypes,
   fieldRequiredTypes,
   formViewTypes,
   qrCodeTypes,
   taskClasses,
   resourceTypes,
   taskViewTypes,
   routineStates,
   reportStates,
   taskStates,
   taskTimingStates,
   taskStatesFilterTypes,
   taskTimingFilterTypes,
   taskViewStates,
   answerStates,
   commitStates,
   formComponentNames,
   optionsFormItemDisplayTypes,
   repeaterFormItemTypes,
   repeaterCadenceTypes,
   datetimeFormItemTypes,
   mergeTypes,
   optionIfTypes,
   optionThenTypes,
   operatorTypes,
   valueTypes,
   taskFilterTypes,
   reportTypes,
   taskMetricsAggregationTypes,
   actionFlags,
   channelTypes
};
module.exports = AppConstants;
