const { newGuid, cast, newShortTimeStamp } = require('../../utils');
var DomainException = require('../DomainException');
const AggregateRoot = require('../ddd/AggregateRoot');
const { types } = require('../../validation');
const _ = require('../../lodash');
const constants = require('../../constants');
const { issueStates, whoAssignmentTypes, whoUnionType } = constants;
const moment = require('moment-timezone');

class Channel extends AggregateRoot {
   constructor({ user, logger }) {
      super({ user, logger });
   }

   get agt() {
      return 'Channel';
   }

   async createNew({ idChannel, noChannel, title, noChannelType }) {
      const tid = this.domainUser.tid;

      let p = {
         aid: idChannel,
         noChannel,
         title,
         noChannelType,
         tid,
         frames: []
      };

      this.apply('CHANNEL_CREATED', p);

      var o = _.omit(p, ['aid', 'tid']);

      this.setData(
         {
            properties: _.merge(
               {
                  id: p.aid
               },
               o
            )
         },
         true
      );
   }

   deleteFrame({ idFrame, actionedOn }) {
      idFrame = idFrame?.toLowerCase();

      const { noChannel } = this._properties;

      const idx = this._properties.frames.findIndex((frame) => frame.id === idFrame);
      let frame = idx !== -1 ? this._properties.frames[idx] : null;

      const noFrame = frame ? frame.no : null;

      let p = {
         noChannel,
         idChannel: this.id,
         noFrame,
         idFrame,
         actionedOn
      };

      this.apply('FRAME_DELETED_FROM_CHANNEL', p);

      if (frame != null) {
         frame.deleted = true;
         frame.upd = actionedOn;
      }
      return frame;
   }

   addFrame({ idFrame, meta, content, actionedOn }) {
      const { noChannel } = this._properties;

      let p = {
         noChannel,
         idChannel: this.id,
         idFrame,
         meta,
         content,
         actionedOn
      };

      this.apply('FRAME_ADDED_TO_CHANNEL', p);

      const frame = {
         id: idFrame,
         upd: actionedOn,
         noUser: this.domainUser.noUser,
         deleted: false,
         content: content,
         meta: meta
      };

      this._properties.frames.push(frame);

      return { ...frame };
   }

   // protected
}

module.exports = Channel;
