const _ = require('../../../lodash');
const BaseEntry = require('./BaseEntry');
const constants = require('../../../constants');
const DomainException = require('../../DomainException');
const { answerStates } = constants;
const { countDecimals } = require('../../../utils/notools');

class ToDoEntry extends BaseEntry {
   constructor(aggregateRoot, componentData, items, entries, ruleEvaluator) {
      super(aggregateRoot, componentData, items, entries, ruleEvaluator);
   }

   markComplete({ actionedOn, geoLat, geoLong, geoTimeStamp }) {
      const item = this.item;

      const newItemState = item.state | answerStates.ANSWERED;

      const noResponse = item.response ? item.response.no : 1;

      const p = {
         idItem: this.idItem,
         state: newItemState,
         actionedOn,
         noResponse: noResponse,
         noSubmissionItem: item.no,
         noSubmission: this._aggregateRoot.getSubmissionNo(),
         geoLat,
         geoLong,
         geoTimeStamp
      };

      this.apply('TODO_MARKED_AS_COMPLETE', p);

      item.response = item.response || {};
      item.response.no = noResponse;
      item.response.val = true;
      item.response.actionedOn = actionedOn;
      item.state = newItemState;
      item.geoLat = geoLat;
      item.geoLong = geoLong;
      item.geoTimeStamp = geoTimeStamp;
   }

   markIncomplete({ actionedOn }) {
      const item = this.item;

      const newItemState = item.state | answerStates.ANSWERED;

      const noResponse = item.response ? item.response.no : 1;

      const p = {
         idItem: this.idItem,
         state: newItemState,
         actionedOn,
         noResponse: noResponse,
         noSubmissionItem: item.no,
         noSubmission: this._aggregateRoot.getSubmissionNo(),
         geoLat: null,
         geoLong: null,
         geoTimeStamp: null
      };

      this.apply('TODO_MARKED_AS_INCOMPLETE', p);

      item.response = item.response || {};
      item.response.no = noResponse;
      item.response.val = false;
      item.response.actionedOn = actionedOn;
      item.state = newItemState;
      item.geoLat = null;
      item.geoLong = null;
      item.geoTimeStamp = null;
   }

   lock() {
      const item = this.item;
      const { state } = item;
      item.state = state | answerStates.LOCKED;
   }

   unlock() {
      const item = this.item;
      const { state } = item;
      item.state = state & ~answerStates.LOCKED;
   }

   validate() {
      const item = this.item;
      const { state } = item;

      let isInvalid = false;

      let isAnswered = (state & answerStates.ANSWERED) == answerStates.ANSWERED;
      let isSkipped = (state & answerStates.SKIPPED) == answerStates.SKIPPED;

      if (!item.response.val && !isSkipped) {
         isInvalid = true;
      }

      if (isInvalid) {
         item.state = state | answerStates.INVALID;
      } else {
         item.state = state & ~answerStates.INVALID;
      }
      return !isInvalid;
   }

   getFriendlyValue() {
      const item = this.item;
      let value = item.response && item.response.val !== undefined ? item.response.val : false;

      return value ? 'Done' : 'Not done';
   }
}

module.exports = ToDoEntry;
