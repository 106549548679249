const unknownVersion = {
   version: 'Unknown',
   commit: 'Unknown',
   branch: 'Unknown',
   date: 'Unknown'
};

function parseXVersion(xVersionString) {
   // Given a string like `23.1.2.0 -f7b21bd from master on Tue, 17 Jan 2023 14:55:06 GMT`
   // extract the various bits
   try {
      const [version, rest] = xVersionString.split(' -');
      const fromIndex = rest.indexOf(' from');
      const commit = rest.substring(0, Math.min(fromIndex, 8)); // Extract up to the first 8 chars or until " from"
      const branchAndDate = rest.substring(fromIndex + 1).split(' on ');
      return {
         version,
         commit,
         branch: branchAndDate[0].replace('from ', ''),
         date: branchAndDate[1]
      };
   } catch {
      return unknownVersion;
   }
}

module.exports = {
   parseXVersion
};
